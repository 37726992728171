// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// import getUpdatedState from "./getUpdatedState"

// ** UseJWT import to get config
// import useJwt from "@src/auth/jwt/useJwt"

// const config = useJwt.jwtConfig

// const initialUser = () => {
//   const item = window.localStorage.getItem("userData")
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }

export const socketSlice = createSlice({
  name: "websocket",
  initialState: {
    isLoggedin: null,
    error: null,
    success: null
  },
  reducers: {
    connect: (state, action) => {
      state.isLoggedin = false
    },

    isConnected: (state) => {
      if (localStorage.getItem("jwt")) {
        state.isLoggedin = true
      }
    },
    // send reducer is used to send ws messages (see middleware websocket/send case).
    // it does not save any state
    send: () => {},

    disconnect: (state) => {
      state.isLoggedin = null
    },
    loading: (state, action) => {
      state.loading = action.payload
    },

    success: (state, action) => {
      state.success = action.payload
    },
    error: (state, action) => {
      state.error = action.payload
    },
    clear: (state) => {
      state.error = false
      state.success = false
    }
  }
})

export const {
  isLoggedin,
  connect,
  onopen,
  errorMessage,
  success,
  clear,
  send,
  test,
  disconnect
} = socketSlice.actions

export default socketSlice.reducer
