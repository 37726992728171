// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const commonSlice = createSlice({
  name: "common",
  initialState: {},

  reducers: {
    getListOfCountries: (state, action) => {
      state.countries = action.payload.countries
      state.languages = action.payload.languages
      state.countriesCountryNameKey = {}
      state.countriesCountryAbbrevKey = {}
      state.langsCountryNameKey = {}
      state.langsCountryAbbrevKey = {}
      action.payload.countries.map((item, i) => {
        state.countriesCountryNameKey[item.label] = item
        state.countriesCountryAbbrevKey[item.value] = item
      })
      action.payload.languages.map((item, i) => {
        state.langsCountryNameKey[item.label] = item
        state.langsCountryAbbrevKey[item.value] = item
      })
    },
    getProviderSources: (state, action) => {
      state.sources = action.payload.sources
    },
    getPlans: (state, action) => {
      state.plans = action.payload.plans
    },
    getCurrencies: (state, action) => {
      const currencies = action.payload.currencies
      const newCurrencies = {}
      currencies.forEach((curr) => {
        newCurrencies[curr.name] = {
          id: curr.id,
          label: curr.name,
          value: curr.code,
          logoUrl: curr.logoUrl
        }
      })
      state.currencies = newCurrencies
    },
    addSource: (state, action) => {
      if (!state.sources) {
        state.sources = []
      }
      state.sources.forEach((source, i) => {
        if (source.id && source.id === action.payload.source.id) {
          state.sources[i] = action.payload.source
        }
      })
      // I do not know if source is added or updated

      toast.success(
        `Source ${action.payload.source.name} was added or updated successfully!`
      )
    },
    deleteSource: (state, action) => {
      const remainingSources = state.sources.filter(
        (source) => source.id !== action.payload.id
      )
      state.sources = remainingSources
      toast.success(`Source was deleted successfully!`)
    }
  }
})

export const { getListOfCountries, getProviderSources } = commonSlice.actions

export default commonSlice.reducer
