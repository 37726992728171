// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const movieSlice = createSlice({
  name: "movie",
  initialState: {},

  reducers: {
    searchMovies: (state, action) => {
      state.searchResults = action.payload.movies
    },
    getMovieGenres: (state, action) => {
      state.movieGenres = action.payload.movieGenres.map((genre, i) => ({
        value: genre.name,
        label: genre.name
      }))
    },
    getProviderMovies: (state, action) => {

      state.numberOfResults = action.payload.numberOfResults
      state.totalCount = action.payload.totalCount

      const movies = [...action.payload.movies]

      state.movies = {}

      movies
        // .sort((a, b) => {
        //   const nameA = a.title.toUpperCase() // ignore upper and lowercase
        //   const nameB = b.title.toUpperCase() // ignore upper and lowercase
        //   if (nameA < nameB) {
        //     return -1
        //   }
        //   if (nameA > nameB) {
        //     return 1
        //   }

        //   // names must be equal
        //   return 0
        // })
        .forEach((movie) => {
          state.movies[movie.id] = movie
        })

      // state.movies = {}
      // action.payload.movies.forEach((movie) => {
      //   state.movies[movie.id] = movie
      // })
    },
    addTmdbMovie: (state, action) => {
      // state.movieId = action.payload.movie.id

      if (state.movies[action.payload.movie.id]) {
        delete state.movies[action.payload.movie.id]
      }

      state.movies = {
        [action.payload.movie.id]: action.payload.movie,
        ...state.movies
      }

      toast.success(
        `Movie ${action.payload.movie.title} was added or updated successfully!`
      )
    },
    addCustomMovie: (state, action) => {
      // if movie.id makes update request

      if (state.movies[action.payload.movie.id]) {
        delete state.movies[action.payload.movie.id]
      }

      state.movies = {
        [action.payload.movie.id]: action.payload.movie,
        ...state.movies
      }

      toast.success(
        `Movie ${action.payload.movie.title} was added or updated successfully!`
      )

      // if (action.payload.movie.id) {
      //   for (let index = 0; index < state.movies.length; index++) {
      //     const movie = state.movies[index]

      //     if (movie.id === action.payload.movie.id) {
      //       state.movies[index] = action.payload.movie
      //       break
      //     }
      //   }
      //   toast.success(`Movie was updated successfully!`)
      //   // if !movie.id makes add request
      // } else {
      //   toast.success(`Movie was added successfully!`)
      // }
    },

    getSelectedMovie: (state, action) => {
      state.selectedMovie = action.payload.movie
    },
    deleteProviderContent: (state, action) => {
      if (action.payload.mediaType === "movie") {
        toast.success(
          `Movie ${
            state.movies[action.payload.id].title
          } was deleted successfully!`
        )

        delete state.movies[action.payload.id]
      }

      // const remainingSources = state.sources.filter(
      //   (source) => source.id !== action.payload.id
      // )
      // state.sources = remainingSources
    }
  }
})

export const {
  searchMovies,
  getMovieGenres,
  getProviderMovies,
  addTmdbMovie,
  getSelectedMovie
} = movieSlice.actions

export default movieSlice.reducer
